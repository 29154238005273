module gmtApp.calendar {
    const moduleName = 'gmtApp.calendar';

    export interface CalendarEvent {
        uuid: string,
        date: Date,
        workflowIDs: number[]
    }

    angular.module(moduleName, ['gmtApp.utilities', 'gmtApp.database', 'gmtApp.workflow', 'ngCordova', 'ui.bootstrap', 'angular-web-notification']);
}
